import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Card, CardContent, Button, TextField, Divider, Typography, Grid, Tooltip, Box } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect, } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '../styles/project.css'
import { useLocation, useNavigate } from 'react-router-dom';
import jiratokenService from '../services/jiratoken.service';
import { fetchJiraTicket, FetchticketList, getJiraFetchEpicWiseTicket } from '../redux/actions/jira';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import '../styles/common.css';
import '../styles/custom.css';
import extractSortAndFilterParams from '../components/Table/filterSortParams';
import ServerPaginationGridNoRowCount from "../components/Table/DataGrid";
import showConfirmationAlert from "../components/Confirm";
import jiraService from '../services/jira.service';
import EditCalendarSharpIcon from '@mui/icons-material/EditCalendarSharp';
import { createJiraAITest, resetJiraAITest } from '../redux/actions/aiTest'
import { getJiraList } from '../redux/actions/jiraToken';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from "@mui/material";


const FetchJira = ({
    fetchJiraTicket, getJiraFetchEpicWiseTicket, DataList, createJiraAITest, getJiraList, jiraTokenList, AITest, resetJiraAITest, FetchticketList, jiraListEpicWise, datacount
}) => {
    const navigate = useNavigate();
    const [TicketsList, setTicketsList] = useState([]);
    const [Base_url, setBase_url] = useState(null);
    const [ProjKey, setProjKey] = useState(null);
    const [Epicid, setEpicid] = useState(null);
    const [totalCount, setTotalCount] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [paginationModals, setPaginationModals] = useState({
        page: 0,
        pageSize: 10,
    });
    const [openRows, setOpenRows] = useState({});

    const [sortModels, setSortModels] = useState([]);
    const [filterModels, setFilterModels] = useState({ items: [] });
    // const columns = [
    //     { field: "projectkey", headerName: "Key" },
    //     { field: "ticket_no", headerName: "Ticket No" },
    //     { field: "summary", headerName: "Summary" },
    //     { field: "issuetype", headerName: "Type" },
    //     { field: "description", headerName: "Description" },
    //     { field: "assigne", headerName: "Assigne" },
    //     { field: "status", headerName: "Status" },
    //     { field: "istestcreated", headerName: "Is Test Created" },
    //     { field: "u_name", headerName: "Created By" },
    //     {
    //         field: "action",
    //         headerName: "Actions",
    //         renderCell: (params) => {
                
    //             return (
    //                 <>
    //                     {/* Tooltip with EditCalendarSharpIcon */}
    //                     {params?.row?.description && params?.row?.istestcreated === false ? (
    //                         <Tooltip title="Create Test Case">
    //                             <EditCalendarSharpIcon
    //                                 sx={{ color: "#34B1AA" }}
    //                                 className="action-icon"
    //                                 onClick={() => createTestCase(params.row)}
    //                             />
    //                         </Tooltip>
    //                     ) : null}

    //                     {/* Expand/Collapse Arrow Button */}
    //                     {params?.row?.id ? (
    //                         <IconButton
    //                             size="small"
    //                             onClick={() =>  setEpicid(params.row.id)
                                    
    //                             }
    //                         >
    //                             {Epicid ? (
    //                                 <KeyboardArrowDown style={{ transition: "transform 0.2s ease" }} />
    //                             ) : (
    //                                 <KeyboardArrowRight style={{ transition: "transform 0.2s ease" }} />
    //                             )}
    //                         </IconButton>
    //                     ) : null}
    //                 </>
    //             );
    //         },
    //     }



    // ];
    // const [width, setWidth] = useState(100 / columns.length + '% !important');
    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
          return text;
        }
        return text?.substring(0, maxLength) + '...';
      };
    useEffect(() => {
        getJiraList(0, 0, null, null, JSON.stringify({}));
        getJiraFetchEpicWiseTicket();
        const { sort, filters } = extractSortAndFilterParams(sortModels, filterModels);
        FetchticketList(paginationModals.page, paginationModals.pageSize, sort.field, sort.sort, JSON.stringify(filters));
    }, [getJiraList, paginationModals.page, paginationModals.pageSize, FetchticketList]
    )

    useEffect(() => {
        // const obj={
        //     case_id:AITest?.case_id
        // }
        if (AITest) {
            resetJiraAITest();
            navigate(`/testcase`, { state: AITest });
        }
    }, [AITest])

    console.log("Data Epic Wise",jiraListEpicWise)

    useEffect(() => {

        const obj = jiraTokenList?.filter(item => item.isdefault === true)
        console.log("obj", obj?.length);

        if (obj?.length <= 0) {
            showConfirmationAlert(
                'No Jira Token Added',  // Title
                'Click to add?',          // Text
                'Add Jira Token',       // Confirm button text
                'Cancel',                 // Cancel button text
                '#34B1AA',                // Confirm button color
                '#f44336c4'               // Cancel button color
            )
                .then((result) => {
                    if (result.isConfirmed) {
                        navigate('/JiraAccount')

                    } else if (result.isDismissed) {
                        setDisabled(true)
                    }
                });

        } else {
            obj?.forEach(item => {
                setBase_url(item.base_url);
                setProjKey(item.projectkey);
            });
            setDisabled(false)
        }

    }, [jiraTokenList]);


    useEffect(() => {
        const dataWithIds = DataList?.rows?.map((row, index) => ({
            ...row,
            id: row.id,
        }));
        setTicketsList(dataWithIds)
        setTotalCount(datacount)
        setExpandedRows(jiraListEpicWise)

    }, [DataList, datacount])
    const createTestCase = (Object) => {
        console.log("object", Object)
        const obj = {
            Ids: Object.id,
            name: Object.summary,
            description: Object.description
        }
        createJiraAITest(obj)

    };

    const toggleRow = (id) => {
        setOpenRows((prevState) => ({
          ...prevState,
          [id]: !prevState[id]
        }));
      };

    const renderChildTable = (parentRowId) => {
        
        console.log(parentRowId)
        
        var parentRecord = expandedRows?.filter((row) => row.epic_id === parentRowId);
        
        if (openRows[parentRowId] && parentRecord) {
          console.log("CHILD RECORDS",parentRecord)
          return (
            <Table>
              <TableHead>
                <TableRow >
                <TableCell>Key</TableCell>
                  <TableCell >Ticket No</TableCell>
                  <TableCell >Summary</TableCell>
                  <TableCell >Type</TableCell>
                  <TableCell >Description</TableCell>
                  <TableCell >Assigne</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parentRecord?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.projectkey}</TableCell>
                    <TableCell>{row.epic_no}</TableCell>
                    <Tooltip title={row.summary}>
                        <TableCell>{truncateText(row.summary, 20)}</TableCell>
                    </Tooltip>
                    <TableCell>{row.issuetype}</TableCell>
                    <Tooltip title={row.description}>
                        <TableCell>{truncateText(row.description, 20)}</TableCell>
                    </Tooltip>
                    <TableCell>{row.assigne}</TableCell>
                    
                    
                  </TableRow>
    
                ))}
              </TableBody>
            </Table>
          );
        }
        return null;
      };

      const tableRows = TicketsList?.map((row, index) => (
        <React.Fragment key={row.id}>
          <TableRow>
            
            <TableCell>{row.projectkey}</TableCell>
            <TableCell>{row.epic_no}</TableCell>
            <Tooltip title={row.summary}>
                <TableCell>{truncateText(row.summary, 20)}</TableCell>
            </Tooltip> 
            <TableCell>{row.issuetype}</TableCell>
            <Tooltip title={row.description}>
                <TableCell>{truncateText(row.description, 20)}</TableCell>
            </Tooltip>          
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.istestcreated?"True":"False"}</TableCell>
            
            <TableCell>
                {row?.istestcreated === false ? (
                            <Tooltip title="Create Test Case">
                                <EditCalendarSharpIcon
                                    sx={{ color: "#34B1AA" }}
                                    className="action-icon"
                                    onClick={() => createTestCase(row)}
                                />
                            </Tooltip>
                ) : null}
                
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => toggleRow(row.id)}>
                {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
        
          </TableRow>
          <TableRow>
            <TableCell colSpan={8}>
              {renderChildTable(row.id)}
            </TableCell>
          </TableRow>
    
        </React.Fragment>
      ));


    return (
        <>
            <div>

                <Card raised>
                    <Typography variant="h5" sx={{ p: 2 }}>
                        Current Jira
                    </Typography>
                    <Divider />
                    <CardContent>
                        <Grid
                            container
                            spacing={2}
                            sx={{ p: 1 }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item xs="auto">
                                <Typography variant="subtitle2" fontSize={14}>
                                    Base Url:
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <TextField value={Base_url} InputProps={{ readOnly: true }} />
                            </Grid>
                            <Grid item xs="auto">
                                <Typography variant="subtitle2" fontSize={14}>
                                    Project Key:
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <TextField value={ProjKey} InputProps={{ readOnly: true }} />
                            </Grid>
                            <Grid item xs="auto">
                                <Tooltip title="Fetch Jira Ticket">
                                    <Button onClick={() => fetchJiraTicket()} disabled={disabled}>Fetch Ticket</Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>


                <br />
                <Box>
                    <Card
                        raised
                        sx={{
                            minWidth: "100%",
                            overflowX: "auto"
                        }}>
                        <Typography variant="h5" sx={{
                            p: 2
                        }}>
                            Jira Ticket List
                        </Typography>

                        <Divider></Divider>


                        <CardContent>

                            {/* <Box style={{ height: 400, width: "100%" }}>
                                {TicketsList?.map((row) => (
                                    <Box key={row.id} sx={{ mb: 2 }}>
                                        <DataGrid
                                            rows={[row]}
                                            columns={columns}
                                            hideFooter
                                            disableColumnMenu
                                            disableSelectionOnClick
                                            components={{
                                                NoRowsOverlay: () => null,
                                            }}
                                        />
                                        {expandedRows?.includes(row) && (
                                            <Box sx={{ pl: 4, pt: 1 }}>

                                                <DataGrid
                                                    rows={row || []}
                                                    columns={[{ field: "epic_id", headerName: "ID" }, { field: "assigne", headerName: "aAssigne", flex: 1 }]}
                                                    hideFooter
                                                    disableColumnMenu
                                                    disableSelectionOnClick
                                                    components={{
                                                        NoRowsOverlay: () => null,
                                                    }}
                                                    sx={{ height: row?.length * 52 + 52 }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                            </Box> */}
                            {/* {TicketsList &&
                                <ServerPaginationGridNoRowCount
                                    setPaginationModals={setPaginationModals}
                                    paginationModals={paginationModals}
                                    rowsData={TicketsList.length > 0 ? TicketsList : []}
                                    total={datacount}
                                    columns={columns}
                                    columnWidth={width}
                                    setSortModels={setSortModels}
                                    sortModels={sortModels}
                                    setFilterModels={setFilterModels}
                                    filterModels={filterModels}
                                />}
                                {expandedRows?.includes(Epicid) && (
                                    <Box sx={{ pl: 4, pt: 1 }}>
                                        
                                        <DataGrid
                                            rows={expandedRows || []}
                                            columns={[{ field: "epic_id", headerName: "ID" }, { field: "assigne", headerName: "aAssigne", flex: 1 }]}
                                            hideFooter
                                            disableColumnMenu
                                            disableSelectionOnClick
                                            components={{
                                                NoRowsOverlay: () => null,
                                            }}
                                            sx={{ height: expandedRows?.length * 52 + 52 }}
                                        />
                                    </Box>
                                )} */}
                                <TableContainer component={Card}>
            <Table className='dashboard-table  TableCell table-responsive '>
              <TableHead >
                <TableRow className='th'>
                  <TableCell >Key</TableCell>
                  <TableCell >Ticket No</TableCell>
                  <TableCell >Summary</TableCell>
                  <TableCell >Type</TableCell>
                  <TableCell >Description</TableCell>
                  <TableCell >Status</TableCell>
                  <TableCell >Is TestCase Created</TableCell>

                  <TableCell >Expand</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows}
              </TableBody>
            </Table>

          </TableContainer>
                             

                        </CardContent>

                    </Card>
                </Box>


            </div>
            <div>


            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    DataList: state.jira?.FetchJiraList,
    jiraTokenList: state.jiraToken?.JiraTokenList?.rows,
    jiraListEpicWise: state.jira?.JiraListEpicWise?.rows,
    datacount: state.jira?.FetchJiraList?.count,
    AITest: state.aiTest?.jiraAITest


});

const mapDispatchToProps = {
    fetchJiraTicket,
    createJiraAITest,
    getJiraList,
    resetJiraAITest,
    FetchticketList,
    getJiraFetchEpicWiseTicket,
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchJira);


