import { CREATE_AI_TEST, JIRA_AI_TEST, RESET_AI_TEST } from "./types";
import AITestService from "../../services/aiTest.service";
import { toast } from "react-toastify";

export const createAITest = (object) => async (dispatch) => {
  try {
    let res = await AITestService.createAITest(object);
    if (res.status === 200) {
      dispatch({
        type: CREATE_AI_TEST,
        payload: res.data,
      });
      toast.success("AI test created successfully");
    } else {
      toast.error("Unable to create AI test " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const createJiraAITest = (object) => async (dispatch) => {
  try {
    toast.success("Creating AI Test Case");
    let res = await AITestService.createJiraAITest(object);

    if (res.status === 200) {
      console.log("Jira AI Ticket", res);
      dispatch({
        type: JIRA_AI_TEST,
        payload: res.data,
      });
      toast.success("AI test created successfully");
    } else {
      toast.error("Unable to create AI test " + res.status);
    }
  } catch (error) {
    toast.error(error);
  }
};

export const resetJiraAITest = () => async (dispatch) => {
  dispatch({
    type: RESET_AI_TEST,
    payload: null,
  });
};
