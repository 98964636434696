import { CREATE_JIRA_TICKET,GET_JIRA_LIST,FETCH_JIRA,FETCH_JIRA_EPIC_WISE } from "../actions/types";
    
      const initialState = {
        JiraList: null,
        FetchJiraList:null,
        JiraListEpicWise:null
      };
      
      const jira = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          case GET_JIRA_LIST:
            return {
              ...state,
              JiraList: payload,
              
          };          
            case CREATE_JIRA_TICKET:
              return {
                ...state,
                isjiraCreated: true,
            };
            case FETCH_JIRA:
              return {
                ...state,
                FetchJiraList: payload,
            };
            case FETCH_JIRA_EPIC_WISE:
              return {
                ...state,
                JiraListEpicWise: payload,
            };

          default:
            return state;
        }
      };
      export default jira;
      