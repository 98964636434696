import { CREATE_AI_TEST,JIRA_AI_TEST,RESET_AI_TEST } from "../actions/types";
    
      const initialState = {
        clockisAITestCreatededList: null,
        jiraAITest:null
      };
      
      const aiTest = (state = initialState, action) => {
        const { type, payload } = action;
      
        switch (type) {
          
            case CREATE_AI_TEST:
              return {
                ...state,
                isAITestCreated: true,
            };
            case JIRA_AI_TEST:
              return {
                ...state,
                jiraAITest: payload,
            };
            case RESET_AI_TEST:
              return {
                ...state,
                jiraAITest: null,
            };

          default:
            return state;
        }
      };
      export default aiTest;
      