import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class AITestService {
    constructor() {
        this.headers = createHeaders();
     }

    createAITest = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/ai`;
            const response = await axios.post(url, obj , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    createJiraAITest = async (obj) => {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/ai/AIJIRATestCase`;
            const response = await axios.post(url, obj , this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
}


export default new AITestService();