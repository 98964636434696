import React from 'react'

const logoStyle = {
  width: "140px",
  
  cursor: "pointer",
  paddingLeft: "2px",
};

function Logo() {
  return (

      <img
        src={"https://qa.girikon.ai/wp-content/uploads/2024/12/girikQA.png"}
        style={logoStyle}
        alt="logo of sitemark"
      />

  );
}

export default Logo